import { FC, FunctionComponent, MutableRefObject, memo, useCallback } from "react";
import {
    DataGridPremium,
    DataGridPremiumProps,
    GridCallbackDetails,
    GridCellParams,
    GridColumnOrderChangeParams,
    GridColumns,
    GridColumnVisibilityModel,
    GridEventListener,
    GridExperimentalPremiumFeatures,
    GridInputSelectionModel,
    GridPinnedColumns,
    GridRowIdGetter,
    GridRowParams,
    GridSelectionModel,
    GridSortModel,
    GridValidRowModel,
    useGridApiRef
} from '@mui/x-data-grid-premium';
import LinearProgress from '@mui/material/LinearProgress';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useMui } from "hooks";
import {
    DetailContentProps,
    EnabledTablePlugins,
    PaginationArgs,
    TablePlugin
} from "ui/molecules/TableGrids/Props";
import type { PaginateResourceResponse } from "types";

// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import NoDataOverlay from './NoDataOverlay';
import TableToolbar from "./Toolbar";
import usePlugins from "./Plugins";
import type { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import type { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { CopyToClipboardSettings } from "./Plugins/useCopyToClipboard";

type Props = {
    readonly columns: GridColumns;
    readonly data: PaginateResourceResponse<any>['data']['data'];
    readonly currentPage: number;
    readonly perPage: number;
    readonly total: number;
    readonly Toolbar?: FunctionComponent;
    readonly isLoading: boolean;
    readonly DetailPanelContent?: FunctionComponent<DetailContentProps>;
    readonly onPageChange: (parimationParams: PaginationArgs) => void;
    readonly onSortModelChange: (
        sortModel: GridSortModel,
        details: GridCallbackDetails<any>) => void;
    readonly sortModel: GridSortModel;
    readonly columnVisibilityModel: GridColumnVisibilityModel;
    readonly onColumnOrderChange: (params: GridColumnOrderChangeParams) => void;
    readonly onColumnVisibilityModelChange: (model: GridColumnVisibilityModel) => void;
    readonly getRowId: GridRowIdGetter<any>;
    readonly checkboxSelection?: boolean;
    readonly disableSelectionOnClick?: boolean;
    readonly pinnedColumns?: GridPinnedColumns;
    readonly CardProps?: CardProps;
    readonly onRowClick?: GridEventListener<"rowClick">;
    readonly plugins?: EnabledTablePlugins;
    readonly initialState?: GridInitialStatePremium;
    readonly isRowSelectable?: (params: GridRowParams) => boolean;
    readonly selectionModel?: GridInputSelectionModel;
    readonly onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
    readonly isCellEditable?: (params: GridCellParams) => boolean;
    readonly experimentalFeatures?: Partial<GridExperimentalPremiumFeatures>;
    readonly processRowUpdate?: <T extends GridValidRowModel>(newRow: T, oldRow: T) => any;
    readonly onCellEditStop?: GridEventListener<"cellEditStop">;
    readonly tableApiRef?: MutableRefObject<GridApiPremium | undefined>;
    readonly NoRowsOverlay?: FC;
    readonly rowsPerPageOptions?: Array<number>;
};

const Table: FC<Props> = ({
    columns,
    data,
    checkboxSelection,
    disableSelectionOnClick,
    currentPage,
    perPage,
    total,
    isLoading,
    DetailPanelContent,
    sortModel,
    columnVisibilityModel,
    onPageChange,
    onSortModelChange,
    onColumnOrderChange,
    onColumnVisibilityModelChange,
    getRowId,
    onRowClick,
    plugins,
    initialState,
    pinnedColumns,
    isRowSelectable,
    selectionModel,
    onSelectionModelChange,
    isCellEditable,
    experimentalFeatures,
    processRowUpdate,
    onCellEditStop,
    tableApiRef,
    rowsPerPageOptions,
    CardProps = {},
    Toolbar = TableToolbar,
    NoRowsOverlay = NoDataOverlay
}) => {
    useMui({
        timeout: 50
    });

    const apiRef = useGridApiRef();

    if (tableApiRef) {
        tableApiRef.current = apiRef.current;
    }

    const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] =
        DetailPanelContent
            ? ({ row }: DetailContentProps) => (
                <DetailPanelContent row={row} />
            )
            : undefined;

    const getDetailPanelHeight: DataGridPremiumProps['getDetailPanelHeight'] =
        useCallback(() => 'auto' as const, []);

    const handlePageChange = (newPage: number) => {
        onPageChange({ page: newPage + 1, perPage });
    };

    const handlePageSizeChange = (perPage: number) => {
        onPageChange({ page: 1, perPage });
    };

    const getExperimentalFeatures: () => Partial<GridExperimentalPremiumFeatures> | undefined = () => {
        if (!experimentalFeatures && isCellEditable) {
            return {
                newEditingApi: true
            };
        }

        return experimentalFeatures;
    };

    usePlugins(apiRef, plugins!);

    return (
        <Box
            m={3}
            pb={3}
        >
            <Card
                sx={{
                    height: `calc(100vh - 210px)`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    userSelect: 'none',
                    ...CardProps.sx
                }}
            >
                <DataGridPremium
                    apiRef={apiRef}
                    pagination
                    checkboxSelection={checkboxSelection}
                    disableSelectionOnClick={disableSelectionOnClick}
                    hideFooterSelectedRowCount
                    rows={data}
                    getRowId={getRowId}
                    loading={isLoading}
                    columns={columns}
                    components={{
                        Toolbar,
                        NoRowsOverlay,
                        LoadingOverlay: LinearProgress,
                    }}
                    density='compact'
                    paginationMode="server"
                    sortingMode="server"
                    page={currentPage - 1}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={onSortModelChange}
                    onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                    onColumnOrderChange={onColumnOrderChange}
                    sortModel={sortModel}
                    pinnedColumns={pinnedColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    pageSize={perPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    rowCount={total}
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={getDetailPanelContent}
                    onRowClick={onRowClick}
                    initialState={initialState}
                    isRowSelectable={isRowSelectable}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    isCellEditable={isCellEditable}
                    experimentalFeatures={getExperimentalFeatures()}
                    processRowUpdate={processRowUpdate}
                    onCellEditStop={onCellEditStop}
                />
            </Card>
        </Box>
    );
};

Table.defaultProps = {
    CardProps: {
        sx: {}
    },
    plugins: {
        [TablePlugin.CopyToClipboard]: {
            isEnabled: true,
            ignoreFields: ['__', 'actions']
        } as CopyToClipboardSettings
    }
};

export default memo(Table);
