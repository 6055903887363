import type { Scalar } from "types";
import { stringifyUrlQueryParams } from "./support";
import { MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { BooleanSwitchValue } from "consts/general";
import { Option } from "./option";

export const urlSearchparamTransformer = <T extends Object>(params: T) => {
    const stringifiedParams = Object.entries(params).reduce((urlSearchParams, [key, value]) => {
        if (Array.isArray(value)) {
            for (const paramValue of value) {
                urlSearchParams.append(key, paramValue);
            }
        } else if (typeof value !== 'undefined') {
            urlSearchParams.append(key, value);
        }

        return urlSearchParams;
    }, new URLSearchParams());

    return stringifyUrlQueryParams(stringifiedParams);
};

export const requestNullValueSanitizer = <T extends Object>(params: T) =>
    Object.entries(params).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value ?? undefined
    }), {});

export const convertArrayToObject = <T extends string>(array: Array<T>, defaultValue = null) =>
    array.reduce((acc, property) => ({
        ...acc,
        [property]: defaultValue
    }), {});

export const convertArrayToMap = (array: Array<any>, indexField: string) =>
    array.reduce((accumulatedMap: Map<string, any>, currentArrayElement) =>
        accumulatedMap.set(
            currentArrayElement instanceof Object
                ? currentArrayElement[indexField]
                : currentArrayElement,
            currentArrayElement
        )
    , new Map<string, any>());

export const transformErrorResponseIntoMessage = (errorResponsePayload: Record<string, Array<string>>) =>
    Object.values(errorResponsePayload)
        .reduce((accMessage, currentError) => `
            ${accMessage}
            ${currentError.join()}
        `, '');

export const convertToFormData = (
    entity:
        | Array<[string, any]>
        | Record<string, any>,
    formElement?: HTMLFormElement
) => {
    const reducedEntity = Array.isArray(entity)
        ? entity
        : Object.entries(entity);
    return reducedEntity.reduce((formData: FormData, [key, value]) => {
        if (typeof value !== 'undefined') {
            formData.append(key, value);
        }
        return formData;
    }, new FormData(formElement));
};

export const convertToOptions = (
    valueOrRecord: string | Array<string> | Record<string, string>,
    transformStrategy?: (key: string, value?: string) => string
) => {
    if (Array.isArray(valueOrRecord)) {
        return valueOrRecord.map(key => Option.make(
            key,
            transformStrategy?.(key) ?? key
        ));
    }

    if (typeof valueOrRecord === 'object') {
        return Object.entries(valueOrRecord)
            .map(([key, value]) => Option.make(
                value,
                transformStrategy?.(key, value) ?? key
            ));
    }

    return Array.of(
        Option.make(
            valueOrRecord,
            transformStrategy?.(valueOrRecord) ?? valueOrRecord
        )
    );
};

// export const convertToOption = (record: Record<string, string>, transformStrategy?: (key: string) => string) =>
//     Object.entries(record)
//         .map(([key, value]) => ({
//             id: value,
//             name: transformStrategy?.(key) ?? key
//         }));

// export const convertArrayToOption = (array: Array<string>, transformStrategy?: (key: string) => string) =>
//     array.map(key => ({
//         id: key,
//         name: transformStrategy?.(key) ?? key
//     }));

export const convertArrayToString = <T extends unknown>(
    array: Array<T>,
    separator = MULTI_COLUMN_SEPARATOR
) => array.filter(Boolean).join(separator);

export const convertToBooleanSwitch = <T extends Scalar<boolean>>(value: T) =>
    value
        ? BooleanSwitchValue.On
        : BooleanSwitchValue.Off;
