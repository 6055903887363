import { useSearchParams } from 'react-router-dom';
import { useTypedSelector } from 'hooks';
import type { RootState } from "infrastructure/store";
import { FilterVariant } from 'consts/filters';
import { useFilterRequestMapper } from 'ui/widgets/Filters';
import useDefaultValues, { type UseDefaultValues } from './useDefaultValues';
import usePaginator, { type UsePaginator } from './usePaginator';

type UseTableArgs =
    & UseDefaultValues
    & UsePaginator
    & {
        readonly selectIsTableLoading: (state: RootState) => boolean;
        readonly fetchTableData: (filterParams: string) => void;
        readonly filterTypes: Map<FilterVariant, string[]>;
        readonly requestQueryOverrideDecorator?: (
            aggregator: (
                fontendQueryParams: URLSearchParams,
                backendQueryParams: URLSearchParams
            ) => void
        ) => (
            fontendQueryParams: URLSearchParams,
            backendQueryParams: URLSearchParams
        ) => void;
    };

export default function useTable({
    selectIsTableLoading,
    selectTableDataSlice,
    fetchTableData,
    filterTypes,
    defaultValues,
    rowsPerPageOptions,
    requestQueryOverrideDecorator,
    paginateOptions
}: UseTableArgs) {
    const [browserUrlSearchParams] = useSearchParams();

    const isLoading = useTypedSelector(selectIsTableLoading);

    const getParamsForRequest = useFilterRequestMapper({
        filterTypes,
        requestQueryOverrideDecorator
    });

    const withDefaultValues = useDefaultValues({ defaultValues });

    const fetchData = async (urlSearchParams: URLSearchParams = browserUrlSearchParams) => {
        fetchTableData(
            getParamsForRequest(
                withDefaultValues(urlSearchParams)
            )
        );
    };

    return {
        ...usePaginator({
            selectTableDataSlice,
            paginateOptions,
            rowsPerPageOptions
        }),
        isLoading,
        fetchData
    };
};
