/*interface AbstractResponsePayload {
  readonly error?: string;
}

export const payloadCreator = <P, T, R extends AbstractResponsePayload>(
  apiService: (requestPayload: T) => Promise<Response>,
  applyTransformers?: (requestPayload: P) => T
) =>
  async (
    requestPayload: P,
    { rejectWithValue, fulfillWithValue }: {
      fulfillWithValue: (resolveWith: R) => void
      rejectWithValue: (rejectWith: Response) => void
    }) => {
    let transformedRequestPayload!: T;
    if (typeof applyTransformers === 'function') {
      transformedRequestPayload = applyTransformers(requestPayload);
    }

    const response = await apiService(
      (transformedRequestPayload ?? requestPayload) as T
    );

    if (response.ok) {
      const payload = await response.json();

      if (!payload.success) {
        return rejectWithValue(payload);
      }

      fulfillWithValue(payload);
      return payload;
    }

    return rejectWithValue(response.clone());
  };
*/
import { isFunction } from "formik";
import type { ID } from "types";

export function concat<T>(elements: Array<T>, { token = '.' } = {}) {
  return elements.join(token);
}

export const stringifyUrlQueryParams = (urlQueryParams: URLSearchParams) => {
  const queryParams = urlQueryParams.toString();

  return queryParams ? `?${queryParams}` : '';
};

export function move<T>(
  array: Array<T>,
  from: number,
  to: number
) {
  return array.splice(to, 0, array.splice(from, 1)[0]);
}

export function swap<T>(array: Array<T>, from: number, to: number) {
  [array[from], array[to]] = [array[to], array[from]];
}

export const isEqual = (comparableA: unknown, comparableB: unknown) =>
  (JSON.stringify(comparableA) === JSON.stringify(comparableB));

export const route = (segments: Array<number | string | undefined>, joinToken = '/') => {
  const url = segments.filter(segment => typeof segment !== 'undefined')
    .join(joinToken);

  return url.startsWith(joinToken)
    ? url
    : `${joinToken}${url}`;
};

export const getSearchParams = (url = window.location.href) => new URL(url).searchParams;

export const getSearchParameterFromUrl = ({
  name = '',
  fallbackValue = '',
  url = window.location.href
}) => getSearchParams(url)
  .get(name) ?? fallbackValue;

export const stripExtension = (fileName: string) => fileName.split('.').slice(0, -1).join('.');

export const getFileExtension = (fileKey: string) => fileKey.split('.').pop();

export const generateFileName = ({
  prefix = '',
  fileKey = null,
  extension
}: Readonly<{
  prefix?: string,
  fileKey?: string | null,
  extension?: string
}>) => {
  const fileName = [prefix.replaceAll(' ', '_'), fileKey]
    .filter(Boolean)
    .join('_');

  return [extension ? stripExtension(fileName) : fileName, extension]
    .filter(Boolean)
    .join('.');
};

export const compareIds = (idA: ID, idB: ID) => String(idA) === String(idB);

export const randomColor = (seed = 16777215) => Math.floor(Math.random() * seed).toString(16);

export const range = (start: number, end: number, step: number) =>
  Array.from({
    length: (end - start) / step + 1
  }, (_, i) => i * step + start);

export const fromArray = <T extends unknown>(iter?: Iterable<T> | null) =>
  [iter]
    .flat()
    .filter(Boolean) as Array<T>;


export const trimObjectValues = <
  TValues extends object,
  TInitialValues extends object
>(values: TValues, initialValues = {}) =>
  Object.entries(values)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string'
        ? value.trim()
        : value
    }), initialValues as TInitialValues);

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const exec = (expr: unknown) => (
  isFunction(expr)
    ? expr()
    : expr
);

export const when = (expr: unknown, then: unknown, otherwise?: unknown) => (
  exec(expr)
    ? exec(then)
    : exec(otherwise)
);
